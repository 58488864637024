// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify"; // Assuming you're using react-toastify for notifications
import React from "react";


const firebaseConfig = {
  apiKey: "AIzaSyBoCfRx0FvWJPWNvOh8SV43kmwZ-TDrR5E",
  authDomain: "taqsim-marketplaces.firebaseapp.com",
  projectId: "taqsim-marketplaces",
  storageBucket: "taqsim-marketplaces.firebasestorage.app",
  messagingSenderId: "631343791260",
  appId: "1:631343791260:web:17040972972b0ad1580159",
  measurementId: "G-M28P1XXYL7"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// To listen for incoming messages
export function setupFirebaseMessaging(refreshUnreadCount) {
  onMessage(messaging, (payload) => {
    const { title, body, click_action } = payload.notification || {};
    toast.info(
      <div>
        <strong>{title ?? "New Notification"}</strong>
        <p>{body ?? "You have a new message"}</p>
      </div>,
      { position: "bottom-right", autoClose: 5000, onClick: () => {
        if (click_action) {
          window.location.href = click_action;
        }
      }, }
    );

    refreshUnreadCount();
  });
}

// Function to request notification permission and get a token
export const getFirebaseToken = async () => {
  try {
    const token = await getToken(messaging, {
      vapidKey:
        "BGfIJYFuw4A3dUhb4xiih74PJkCzAPgMt3_ltLYEkUkY5s-EX8uSiKrFzB0P4NXFNzZFBSNb84yGmQO2eca-gZ8",
    });
    console.log(messaging);
    return token || null;
    
  } catch (err) {
    console.error("Error retrieving Firebase token:", err);
    return null;
  }
};

// Function to request permission for notifications (if not granted yet)
export const requestPermission = () => {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification permission granted.");
    }
  });
};

