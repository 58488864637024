import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  Spinner,
  Label,
} from "reactstrap";
import { Input } from "../../Components/atoms/input";
import { Input as BootstrapInput } from "reactstrap";
import { login } from "../../api/auth";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import useHookForm from "../../hooks/useHookForm";
import createSchema from "../../helpers/createSchema";
import { v4 as uuidv4 } from "uuid";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer, toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import InputMasked from "../../Components/atoms/inputMask";
import removeChars from "../../Components/atoms/removeChars";
import { getToken } from "firebase/messaging";
import {  analytics, getFirebaseToken, messaging, requestPermission, setupFirebaseMessaging} from "../../helpers/firebase";
const values = {
  phoneNumber: "998",
  password: "",
  isTrusted: true,
  deviceId: uuidv4(),
  deviceType: 3,
  displayName: navigator.userAgent,
  userType: 3,
  otp: "",
};

const Login = () => {
  const [change, setChange] = useState(true);
  const [timer, setTimer] = useState(0);
  const schema = createSchema({
    phoneNumber: "phone",
    password: "auth_password",
    ...(!change && timer ? {otp: "required"} : null)
  });
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [check, setCheck] = useState(true);
  const [phone, setPhone] = useState("");
  const [show, setShow] = useState(false);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const navigate = useNavigate();
  const clients = useQueryClient();
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useHookForm(values, schema);
  const toggleEye = () => {
    setShow(!show);
  };
  const onChange = (event) => {
    const checked = event.target.checked;
    setCheck(checked);
    setValue("isTrusted", checked);
  };
  useEffect(() => {
    let interval = null;

    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsTimerActive(false);
      clearInterval(interval);
    } 

    return () => clearInterval(interval);
  }, [timer, isTimerActive]);
  const onSubmit = handleSubmit(async (res) => {
   

    setIsTimerActive(true);
    setTimer(120);
    setLoading(true);
    setPhone(res.phoneNumber);
    const loginData = {
      ...res,
      deviceId: values.deviceId,
      deviceType: values.deviceType,
      displayName: values.displayName,
      userType: values.userType,
      phoneNumber: removeChars(res.phoneNumber),
    };
    try {
      const res = await login(loginData, "POST");
      toast(res.result.sentText, { type: "success", theme: "colored" });
      setChange(false);
      clients.invalidateQueries({ queryKey: ["profile"] });
    } catch (error) {
      toast(error.data?.error.errorMessage, {
        type: "error",
        theme: "colored",
      });
    } finally {
      setLoading(false);
    }
  });

//   const onSubmitOtp = handleSubmit(async (res) => {
//     setLoading2(true);
//     let firebaseToken = "";
//     try {
//       const firebaseResponse = await getToken(messaging, {
//         vapidKey: "BASpSuOqMqAGAEWC443qas93uPZ_rz-3vY85v2rtCN9NBIvkn7KrBLHQ2AbejP8o5_AAnoyF7UOUaBbJaFmNv3s",
//       });
  
//       if (firebaseResponse) {
//         firebaseToken = firebaseResponse.authToken?.token; 
//       } else {
//         throw new Error("Firebase response is empty or invalid.");
//       }
//     } catch (error) {
//       console.error("Failed to fetch Firebase token:", error);
//       firebaseToken = ""; 
//     }
// console.log(messaging);
    
//     const loginData = {
//       // ...res,
//       phoneNumber: removeChars(phone),
//       otp: res.otp,
//       deviceId: values.deviceId,
//       deviceType: values.deviceType,
//       displayName: values.displayName,
//       userType: values.userType,
//       firebaseToken
//     };
//     try {
//       const response = await login(loginData, "PUT");
//       localStorage.setItem("firebaseToken", firebaseToken)
//       localStorage.setItem("token", response.result.token);
//       localStorage.setItem("access", JSON.stringify(response.result.access));
//       navigate("/");
//       clients.invalidateQueries({ queryKey: ["profile"] });
//       clients.invalidateQueries({ queryKey: ["profileInfo"] });
//     } catch (error) {
//       toast(error.data?.error.errorMessage, {
//         type: "error",
//         theme: "colored",
//       });
//     } finally {
//       setLoading2(false);
//     }
//   });
const onSubmitOtp = handleSubmit(async (res) => {
  setLoading2(true);
  
  try {
    
    await requestPermission()
     const firebaseToken = await getFirebaseToken()
   
     setupFirebaseMessaging(() => {
       console.log("Unread count refreshed!");
      });
      const loginData = {
        phoneNumber: removeChars(phone),
        otp: res.otp,
        deviceId: values.deviceId,
        deviceType: values.deviceType,
        displayName: values.displayName,
        userType: values.userType,
        firebaseToken, 
      };
      
    const response = await login(loginData, "PUT");
    localStorage.setItem("token", response.result.token);
    localStorage.setItem("access", JSON.stringify(response.result.access));
    navigate("/"); // Navigate after successful login
    clients.invalidateQueries({ queryKey: ["profile"] });
    clients.invalidateQueries({ queryKey: ["profileInfo"] });
  } catch (error) {
    toast(error.data?.error.errorMessage, {
      type: "error",
      theme: "colored",
    });
  } finally {
    setLoading2(false);
  }
});


  document.title = "Авторизация | Taqsim Admin";

  return (
    <ParticlesAuth>
      <div className="auth-page-content">
        <ToastContainer position="top-center" />
        <Container>
          <Row className="justify-content-center mt-5">
            <Col md={8} lg={6} xl={5} className="mt-5">
              <Card className="mt-5">
                <CardBody className="p-4">
                  <div className="text-center mt-2 ">
                    <h5 style={{ fontSize: "18px", fontWeight: 580 }}>
                      {t("Partner's office")}
                    </h5>
                    <div className="d-flex flex-column gap-1">
                      <span style={{ fontSize: "23px", fontWeight: 550 }}>
                        {!change ? phone : null}
                      </span>
                      <span>
                        {!change
                          ? t(
                              "An SMS with a code has been sent to the specified number"
                            )
                          : null}
                      </span>
                    </div>
                  </div>
                  <div className="p-2 mt-4">
                    {change ? (
                      <Form onSubmit={onSubmit}>
                        <div className="mb-3">
                          <InputMasked
                            name={"phoneNumber"}
                            label={"Phone number"}
                            control={control}
                            errors={errors}
                            maskProps={{ mask: "\\+\\9\\9\\8 99 999 99 99" }}
                          />
                        </div>
                        <div className="position-relative">
                          <div className="mb-3">
                            <Input
                              name="password"
                              control={control}
                              errors={errors}
                              label="Password"
                              inputProps={{
                                type: show ? "text" : "password",
                                placeholder: t("Password"),
                                style: { paddingRight: "2.5rem" },
                              }}
                            />
                          </div>
                          <Button
                            color="link"
                            onClick={toggleEye}
                            className="position-absolute end-0  translate-middle-y text-decoration-none  password-addon"
                            style={{ zIndex: 99, top: 46 }}
                            type="button"
                          >
                            <i
                              className={
                                !show
                                  ? "ri-eye-off-fill align-middle"
                                  : "ri-eye-fill align-middle"
                              }
                            ></i>
                          </Button>
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                          <div className="form-check">
                            <BootstrapInput
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck2"
                              name="isTrusted"
                              onChange={onChange}
                              checked={check}
                            />{" "}
                            <Label
                              className="form-check-label"
                              for="formCheck2"
                            >
                              {t("Trust this device")}
                            </Label>
                          </div>

                          <Link to={"/forgot-password"}>
                            {t("Forgot your password")}
                          </Link>
                        </div>

                        <div className="mt-4 d-flex justify-content-center">
                          <Button
                          disabled={loading}
                            className="btn btn-primary w-50"
                            type="submit"
                          >
                            {loading ? (
                              <div className="d-flex align-items-center gap-1 justify-content-center">
                                <Spinner size={"sm"} />
                                <div>{t("sign_in")}</div>
                              </div>
                            ) : (
                              <div>{t("sign_in")}</div>
                            )}
                          </Button>
                        </div>
                        <div
                          className="d-flex align-items-center gap-2 justify-content-center mt-2 "
                          style={{ fontSize: "14px" }}
                        >
                          <span>{t("Don't have an account?")}</span>
                          <Link to={"/register"}>{t("Registration")}</Link>
                        </div>
                      </Form>
                    ) : (
                      <Form onSubmit={timer ? onSubmitOtp : onSubmit}>
                        <div className="position-relative">
                        <div className="mb-3">
                          <Input
                            name="otp"
                            control={control}
                            errors={errors}
                            inputProps={{
                              disabled: !timer,
                              type: "text",
                              placeholder: t("Enter confirmation code"),
                            }}
                          />
                          {/* <InputMasked
                            name="otp"
                            control={control}
                            errors={errors}
                            maskProps={{ mask: "999 999" }}
                            inputProps={{
                              type: "text",
                              autoComplete: "off",
                              placeholder: t("Enter confirmation code"),
                            }}
                          /> */}
                          {isTimerActive && (
                            <div className="text-success position-absolute" style={{top: 9, right: 10, zIndex: 99}}>
                              {String(Math.floor(timer / 60)).padStart(2, '0')}:
                              {String(timer % 60).padStart(2, '0')}
                            </div>
                          )}
                        </div>
                        </div>
                        {timer ? (
                          <div className="d-flex justify-content-center ">
                            <Button disabled={loading2} className="w-50 btn btn-primary">
                              {loading2 ? (
                                <Spinner size="sm" className="me-2">
                                  {" "}
                                  Loading...{" "}
                                </Spinner>
                              ) : null}
                              {t("sign_in")}
                            </Button>
                          </div>
                        ) : (
                          <div className="d-flex justify-content-center ">
                            <Button className="w-50 btn btn-primary">
                              {t("reset")}
                            </Button>
                          </div>
                        )}
                      </Form>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </ParticlesAuth>
    // {/* </ToastContainer> */}
  );
};

export default Login;
