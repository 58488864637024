import { useIsFetching, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getProductsQuery,
  getReserveByIdQuery,
  getReserveSubOrdersByIdQuery,
} from "../../../queries";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  List,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  Input as ReactInput,
} from "reactstrap";
import { prettify } from "../ui/prettify";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Controller } from "react-hook-form";
import Select from "react-select";
import * as moment from "moment";
import { Input } from "../../../Components/atoms/input";
import { ToastContainer, toast } from "react-toastify";
import {
  createReserveItem,
  editOrderStatus,
  ReserveItemById,
  ReserveSubOrdersByIdPut,
} from "../../../api";
import * as Yup from "yup";
import Textfield from "../ui/TextField";
import CustomModal from "../../../Components/Common/Modal";
import FilterInputs from "../ui/filter-inputs";
import { config } from "../../../utils/config";

const form = {
  state: 0,
  reason: "",
};

const values = {
  page: 1,
  size: 2147483647,
  isAll: true,
  productName: null,
};
const ReserveSingle = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    reason: Yup.string()
      .nullable()
      .when("state.value", {
        is: 4,
        then: Yup.string().required(t("required_field_error")),
        otherwise: Yup.string(),
      }),
  });
  const { id, subOrderId } = useParams();
  const [formValues, setFormValues] = useState(values);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [itemLoading, setItemLoading] = useState({});
  const [createModal, setCreateModal] = useState(false);
  const [productId, setProductId] = useState([])
  const [orderModal, setOrderModal] = useState(false)
  const [orderLoading, setOrderLoading] = useState(false)
  const navigate = useNavigate();
  const clients = useQueryClient();
  const {
    control,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
  } = useHookForm(form, schema);
  const { data, isLoading } = useQuery({
    ...getReserveSubOrdersByIdQuery(id, subOrderId),
  });
  const { data: products, isLoading: productLoading } = useQuery({
    ...getProductsQuery(formValues),
  });
  const { data: client, isLoading: clientLoading } = useQuery({
    ...getReserveByIdQuery(id),
  });

  const toggleModal = (id) => {
    setModal((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const deleteToggleModal = (id) => {
    setDeleteModal((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const createToggleModal = () => {
    setCreateModal((prev) => !prev);
  };
  const orderModalToggle = () => {
    setOrderModal((prev) => !prev);
  };
  const calculateOrderTotalPrice = (order) => {
    let total = 0;

    order?.result?.items?.forEach((item) => {
      const itemPrice = item?.variation?.prices?.find(
        (price) => price.type === "Price"
      )?.value;
      if (itemPrice) {
        total += itemPrice * item?.count;
      }
    });

    return total;
  };

  useEffect(() => {
    if (data) {
      const value = {
        state:
          data.result.state !== null
            ? {
                value: data.result.state,
                label:
                  data.result.state === 0
                    ? t("reserve_created")
                    : data.result.state === 1
                    ? t("reserve_undrway")
                    : data.result.state === 2
                    ? t("reserve_sent")
                    : data.result.state === 3
                    ? t("reserve_complated")
                    : data.result.state === 4
                    ? t("reserve_cancelled")
                    : data?.result?.state === 5 ? t("reserve_refund") : data?.result?.state === 6 ? t("reserve_processed") : t("reserve_delivered"),
              }
            : null,
        reason: data.result.reason || "",
      };
      reset(value);
    }
  }, [data, reset]);

  const onSubmit = handleSubmit(async (res) => {
    setLoading(true);
    const state = res.state.value;
    const datas = {
      state: state,
      reason: res.reason || "",
    };

    try {
      await ReserveSubOrdersByIdPut(id, subOrderId, datas);

      toast(t("Заказ успешно изменен"), {
        type: "success",
        theme: "colored",
        position: "top-center",
      });
      clients.invalidateQueries({ queryKey: ["orders2-single"] });
      clients.invalidateQueries({ queryKey: ["orders2"] });
    
    } catch (error) {
      toast(error.data?.error?.errorMessage || error.data.message, {
        type: "error",
        theme: "colored",
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  });
  useEffect(() => {
    if (data?.result?.items) {
      const productIds = data.result.items
        .map((item) => item.variation?.product?.id)
        .filter(Boolean); 
  
      setProductId(productIds);
    }
  }, [data, setProductId]);
  
  
  
  const totalPrice = calculateOrderTotalPrice(data);
  const productItemDelete = async (itemId) => {
    setDeleteLoading(true);
    try {
      await ReserveItemById("DELETE", id, subOrderId, itemId);
      clients.invalidateQueries({ queryKey: ["orders2-single"] });
      clients.invalidateQueries({ queryKey: ["orders2"] });
      setDeleteModal(false);
    
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  };
  const productItemCreate = async (variationId) => {
    const formData = {
      count: 1,
      variationId: variationId,
    };

    try {
      await createReserveItem(id, subOrderId, formData);
      clients.invalidateQueries({ queryKey: ["orders2-single"] });
      clients.invalidateQueries({ queryKey: ["orders2"] });
      setCreateModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const productItemEdit = async (itemId, product) => {
    setItemLoading((prev) => ({ ...prev, [itemId]: true }));
    const formData = {
      count: 1,
      variationId: product,
    };
    setModal(false);
    try {
      await ReserveItemById("PUT", id, subOrderId, itemId, formData);
      clients.invalidateQueries({ queryKey: ["orders2-single"] });
    } catch (error) {
      console.log(error);
    } finally {
      setItemLoading((prev) => ({ ...prev, [itemId]: false }));
    }
  };
  const onHandleCheck = (e) => {
    const newCheck = e.target.checked;

    setCheck(newCheck);
  };

  const itemPlusCount = async (itemId, variationId, count) => {
    const formData = {
      count: count + 1,
      variationId: variationId,
    };
    try {
      await ReserveItemById("PUT", id, subOrderId, itemId, formData);
      clients.invalidateQueries({ queryKey: ["orders2-single"] });
      clients.invalidateQueries({ queryKey: ["orders2"] });
    } catch (error) {
      console.log(error);
    }
  };
  const itemMinusCount = async (itemId, variationId, count) => {
    const formData = {
      count: count - 1,
      variationId: variationId,
    };

    try {
      await ReserveItemById("PUT", id, subOrderId, itemId, formData);
      clients.invalidateQueries({ queryKey: ["orders2-single"] });
      clients.invalidateQueries({ queryKey: ["orders2"] });
    } catch (error) {
      console.log(error);
    }
  };
const editOrdersStatus = async () => {
  setOrderLoading(true)
  try {
    await editOrderStatus(id, 12)
    clients.invalidateQueries({ queryKey: ["orders2-single"] });
    clients.invalidateQueries({ queryKey: ["orders2"] });
  } catch (error) {
    console.log(error);
    
  } finally {
setOrderModal(false)
setOrderLoading(false)
  }
}
  const currency = config.currency();
  return (
    <div
      className="page-content"
      style={{
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : (
        <div>
          <ToastContainer />
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-start gap-3 mb-2">
              <i
                className="ri-arrow-left-line fs-3 cursor-pointer"
                onClick={() => navigate(-1)}
              ></i>
              <h2>
                {t("reserve2")} № {client?.result?.id}
              </h2>
            </div>
            <div className="d-flex align-items-center gap-2">
            
              <span>{t("action_check")}</span>
              <div className="form-check form-switch mt-1">
                <ReactInput
                  type="checkbox"
                  role="switch"
                  className="form-check-input"
                  onChange={onHandleCheck}
                  checked={check}
                />
              </div>
              <Button className="btn-success" onClick={createToggleModal}>
                {t("add_reserve")}
              </Button>
              <div hidden={data?.result?.state !== 7}>
              <Button onClick={orderModalToggle}>{t("completion_status")}</Button>
            </div>
            </div>
          </div>
          <Card
            style={{
              borderRadius: "1rem",
            }}
          >
            <CardHeader
              style={{
                borderTopLeftRadius: "1rem",
                borderTopRightRadius: "1rem",
              }}
            >
              <h3>{t("order_information")}</h3>
            </CardHeader>
            <CardBody
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "1rem",
              }}
            >
          <div >
           
          <Textfield name={"created_date"} source={moment(client?.result?.createdDate).format("DD.MM.YYYY")} />
          <Textfield name={"delivery_status"} source={client?.result.deliveryType === 0 ? t("delivery") : t("pickup")} />
          <Textfield name={"Region"} source={client?.result?.regionName} />
          <Textfield name={"District"} source={client?.result?.districtName} />
          <Textfield name={"Address"} source={client?.result?.address} />
          </div>
         <div>
         <Textfield name={"client_full_name"} source={client?.result?.fullName} />
          <Textfield name={"phone_number"} source={client?.result?.phoneNumber} />
          <Textfield name={"comment"} source={client?.result?.comment} />
          <Textfield name={"total_price"} source={prettify(totalPrice) +
                                    " " +
                                    t(currency === "aed" ? "AED" : currency)} />

         </div>

            </CardBody>
          </Card>
          <Row className="justify-content-around">
            <Col xs="12" lg="7">
              <ListGroup style={{ borderRadius: "1rem" }}>
                {data?.result?.items?.map((item, i) => (
                  <>
                    <ListGroupItem
                      key={i}
                      className="d-flex align-items-end justify-content-between"
                    >
                      <div className="d-flex gap-5 justify-content-between">
                        {itemLoading[item.id] ? (
                          <div>
                            <Spinner />
                          </div>
                        ) : (
                          <div className="d-flex gap-3 align-items-start">
                            <img
                              style={{
                                maxWidth: "100px",
                                width: "100px",
                                maxHeight: "150px",
                                borderRadius: "5px",
                              }}
                              src={
                                item.variation?.files?.sort(
                                  (a, b) => a.order - b.order
                                )[0]?.url
                              }
                              alt={item.variation?.files?.[0]?.id}
                            />
                            <div className="d-flex flex-column gap-1">
                              <div style={{ width: "100%" }}>
                                {item?.variation?.product?.name}
                              </div>
                              <strong>
                                {prettify(
                                  item?.variation?.prices?.find(
                                    (price) => price.type === "Price"
                                  )?.value *
                                    item.count +
                                    " " +
                                    t(currency === "aed" ? "AED" : currency)
                                )}
                              </strong>

                              {!check ? (
                                <div>{t("count")}: {item?.count}</div>
                              ) : (
                                <div className="d-flex align-items-center gap-2 mt-2">
                                  <Button
                                    style={{
                                      width: "32px", 
                                      height: "32px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: 0, 
                                    }}
                                    color="light"
                                    onClick={
                                      item.count <= 1
                                        ? null
                                        : () =>
                                            itemMinusCount(
                                              item.id,
                                              item?.variation?.id,
                                              item.count
                                            )
                                    }
                                  >
                                    -
                                  </Button>
                                  {item.count}
                                  <Button
                                    style={{
                                      width: "32px",
                                      height: "32px",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      padding: 0,
                                    }}
                                    color="light"
                                    onClick={() =>
                                      itemPlusCount(
                                        item.id,
                                        item?.variation?.id,
                                        item.count
                                      )
                                    }
                                  >
                                    +
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className={
                          !check
                            ? "d-none"
                            : `d-flex justify-content-end cursor-pointer gap-2`
                        }
                      >
                        <div onClick={() => toggleModal(i)}>
                          <i className="bx bx-pencil text-warning fs-4"></i>
                        </div>
                        <div onClick={() => deleteToggleModal(i)}>
                          <i className="bx bx-trash text-danger fs-4"></i>
                        </div>
                      </div>
                    </ListGroupItem>
                    <CustomModal
                      isOpen={modal[i]}
                      title={t("edit")}
                      toggleModal={() => toggleModal(i)}
                      content={
                        <div>
                          <FilterInputs
                            type={"filter"}
                            setFormValues={setFormValues}
                            fields={[
                              {
                                name: "productName",
                                placeholder: "Product name",
                                width: "400px",
                              },
                            ]}
                          />
                          {productLoading &&
                          formValues.productName !== null &&
                          formValues.productName?.length ? (
                            <div className="d-flex justify-content-center mt-2">
                              <Spinner size={"lg"} />
                            </div>
                          ) : (
                            <div
                              hidden={
                                formValues.productName === null ||
                                formValues.productName?.length === 0
                              }
                              className="mt-4"
                              style={{
                                maxHeight: "350px",
                                overflow: "auto",
                                cursor: "pointer",
                              }}
                            >
                              <ListGroup>
                                {products?.item?.map((product) => (
                                  <ListGroupItem
                                  disabled={productId.find((prev) => prev === product.id
                                  )}
                                    key={product.id}
                                    onClick={() =>
                                      productItemEdit(
                                        item.id,
                                        product?.variations?.[0].id
                                      )
                                    }
                                  >
                                    {product?.name}
                                  </ListGroupItem>
                                ))}
                              </ListGroup>
                            </div>
                          )}
                        </div>
                      }
                    />
                    <CustomModal
                      isOpen={deleteModal[i]}
                      toggleModal={() => deleteToggleModal(i)}
                      title={t("Confirm deletion")}
                      content={
                        <div className="d-flex justify-content-center flex-column gap-2">
                          <i
                            className="ri-alert-fill text-warning d-flex justify-content-center"
                            style={{ fontSize: "50px" }}
                          ></i>
                          <span className="text-center">
                            {t(
                              "Once data is deleted, it cannot be recovered!!!"
                            )}
                          </span>
                        </div>
                      }
                      footerText={
                        <div className="d-flex justify-content-end gap-2 align-items-center">
                          <Button onClick={() => deleteToggleModal(i)}>
                            {t("Cancel")}
                          </Button>
                          <Button
                            onClick={() => productItemDelete(item.id)}
                            color="danger"
                            disabled={deleteLoading}
                          >
                            {deleteLoading ? (
                              <Spinner size={"sm"} />
                            ) : (
                              t("Delete")
                            )}
                          </Button>
                        </div>
                      }
                    />
                  </>
                ))}
              </ListGroup>
            </Col>
            <Col xs="12" lg="5" className="mb-3">
              <Card style={{ borderRadius: "1rem", width: "100%" }}>
                <CardBody>
                  <Form onSubmit={onSubmit}>
                    <div className="mb-3 w-100">
                      <Label for="positionId">{t("status")}</Label>
                      <Controller
                        name="state"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={[
                              { value: 0, label: t("reserve_created") },
                              { value: 1, label: t("reserve_undrway") },
                              { value: 2, label: t("reserve_sent") },
                              { value: 3, label: t("reserve_complated") },
                              { value: 4, label: t("reserve_cancelled") },
                              { value: 5, label: t("reserve_refund") },
                              {value:6, label: t("reserve_processed")},
                              {value:7, label: t("reserve_delivered")}
                            ]}
                            placeholder="Select Position"
                          />
                        )}
                      />
                    </div>
                    <div className="mb-3 w-100">
                      <Input
                        name="reason"
                        control={control}
                        errors={errors}
                        label={t("reason")}
                        inputProps={{
                          placeholder: t("reason"),
                          type: "textarea",
                        }}
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        className="btn btn-primary d-flex align-items-center gap-2  "
                        type="submit"
                      >
                        {loading ? (
                          <Spinner size="sm" className="me-2">
                            Loading...
                          </Spinner>
                        ) : null}
                        {t("Save")}
                        <i className="ri-check-line"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}

      <CustomModal
        isOpen={createModal}
        title={t("add_reserve")}
        toggleModal={() => createToggleModal()}
        content={
          <div>
            <FilterInputs
              type={"filter"}
              setFormValues={setFormValues}
              fields={[
                {
                  name: "productName",
                  placeholder: "Product name",
                  width: "400px",
                },
              ]}
            />
            {productLoading &&
            formValues.productName !== null &&
            formValues.productName?.length ? (
              <div className="d-flex justify-content-center mt-2">
                <Spinner size={"lg"} />
              </div>
            ) : (
              <div
                hidden={
                  formValues.productName === null ||
                  formValues.productName?.length === 0
                }
                className="mt-4"
                style={{
                  maxHeight: "350px",
                  overflow: "auto",
                  cursor: "pointer",
                }}
              >
                <ListGroup>
                  {products?.item?.map((product) => (
                    <ListGroupItem
                    disabled={productId.find((prev) => prev === product.id
                    )}
                      key={product.id}
                      onClick={() =>
                        productItemCreate(product?.variations?.[0].id)
                      }
                    >
                      {product?.name}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </div>
            )}
          </div>
        }
      />
      <CustomModal isOpen={orderModal} toggleModal={orderModalToggle} title={t("completion_status")} content={<div>
        <div className="text-center fs-5">{t("Are_you_sure_you_want_to_finalize_the_order_status")} ?</div>

      </div>} footerText={<div className="d-flex justify-content-end align-items-center gap-3">
  <Button onClick={orderModalToggle}>{t("Cancel")}</Button>
  <Button onClick={editOrdersStatus}>{orderLoading ? <Spinner size={"sm"} /> : t("Yes")}</Button>
</div>}/>
    </div>
  );
};

export default ReserveSingle;
