import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductState, ProductsVisibility, getProductsId } from "../../../api";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import Cleave from "cleave.js/react";
import Dropzone from "react-dropzone";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import StateSelect from "../Components/StateSelect";
import Visibility from "../Components/Visibility";
import { InputLabel } from "../../../Components/atoms/inputLabel";
import { config } from "../../../utils/config";

const ProductsSingle = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const clients = useQueryClient();
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery({
    queryKey: ["products-id", id],
    queryFn: () => getProductsId(id),
    throwOnError: (err) => {
      if (err.response && err.response.status === 400) {
        toast(err.response.data?.error?.errorMessage, {
          theme: "colored",
          type: "error",
        });
      } else {
        toast(err.data?.error?.errorMessage, {
          theme: "colored",
          type: "error",
        });
      }
    },
  });
  let languages = ["ru", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      {isLoading ? (
        <Spinner />
      ) : (
      <Row>
        <Col className="d-flex justify-content-center">
        <Card className="w-100">
          <CardHeader className="d-flex align-items-start gap-3">
            <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
            <h2>{t("Product information")}</h2>
          </CardHeader>
          <CardBody>
          <div className="d-flex gap-5 justify-content-between mb-3">
            <section className="w-50 d-flex flex-column gap-3">
              <StateSelect id={id} data={data} />
              <Label>{t("Visible")}</Label>

              <Visibility id={id} data={data} />
              <div>
                <Label className="fw-semibold">{t("Product category")}</Label>
                <Input
                  className="form-control border-dashed p-3"
                  value={data?.result?.category?.name}
                  readOnly
                />
              </div>
              <div>
                <Label className="fw-semibold">{t("brand_name")}</Label>
                <Input
                  className="form-control border-dashed p-3"
                  value={data?.result?.brand?.id === 1 ? t("no_brand") : data?.result?.brand?.name}
                  readOnly
                />
              </div>
              <div className="d-flex flex-column gap-3">
                {languages.map((lang) => {
                  const item = data?.result?.names.find(
                    (name) => name.languageCode === lang
                  );
                  return (
                    <div key={lang}>
                      <Label className="fw-semibold">
                        {lang === "ru"
                          ? t("Name Russian language")
                          : lang === "uz-Cyrl-UZ"
                          ? t("Name Uzbek language (Cyrill.)")
                          : lang === "uz-Latn-UZ"
                          ? t("Name Uzbek language (lat.)")
                          : lang === "en"
                          ? t("Name English language")
                          : ""}
                      </Label>
                      <Input
                        className="form-control border-dashed p-3"
                        value={item ? item.text : ""}
                        readOnly
                      />
                    </div>
                  );
                })}
              </div>
              <div className="d-flex flex-column gap-3">
                {languages.map((lang) => {
                  const item = data?.result?.descriptions.find(
                    (name) => name.languageCode === lang
                  );
                  return (
                    <div key={lang}>
                      <Label className="fw-semibold">
                        {lang === "ru"
                          ? t("Description Russian language")
                          : lang === "uz-Cyrl-UZ"
                          ? t("Description Uzbek language (Cyrill.)")
                          : lang === "uz-Latn-UZ"
                          ? t("Description Uzbek language (lat.)")
                          : lang === "en"
                          ? t("Description English language")
                          : ""}
                      </Label>
                      <Input
                        className="form-control border-dashed p-3"
                        value={item ? item.text : ""}
                        readOnly
                      />
                    </div>
                  );
                })}
              </div>
                <div className="d-flex align-items-center gap-3 mt-2 flex-column">
                              <div className="w-100">
                                <Label className="fw-semibold">{t("mxikCode")}</Label>
                              <Input value={data?.result?.mxikCode} readOnly className="form-control border-dashed p-3"/>
                              </div>
                              <div className="w-100">
                              <Label className="fw-semibold">{t("packageCode")}</Label>
                              <Input value={data?.result?.packageCode} readOnly className="form-control border-dashed p-3"/>
                              </div>
                            </div>
              {data?.result?.variations?.length ? (
                <div>
                  <div>
                    <div className="d-flex flex-column gap-3">
                      {data?.result?.variations[0]?.prices?.map((item) => (
                        <div key={item.id}>
                          <Label className="fw-semibold">
                            {item.type === "Vat"
                              ? t("VAT amount")
                              : item.type === "Price"
                              ? t("Product price")
                              : item.type === "PayAmount"
                              ? t("Selling price")
                              : item.type === "Installment"
                              ? t("Installment price")
                              : item.type === "Compensation"
                              ? t("Compensation price")
                              : item.type === "Sale"
                              ? t("MSRP")
                              : null}
                          </Label>
                          <Cleave
                            placeholder="Enter numeral"
                            options={{
                              numeral: true,

                              numeralThousandsGroupStyle: "thousand",
                            }}
                            value={item.value}
                            className="form-control border-dashed p-3"
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                      style={{
                        display: "grid",
                        textAlign: "center",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "5px",
                        width: "100%",

                        marginTop: "10px"
                      }}
                    >
                      {data?.result?.variations[0]?.files?.map((item) => (
                        <div
                          id="img2"
                          key={item}
                          className="p-2"
                        >
                          <img
                            src={item.url}
                             className="img-thumbnail rounded avatar-xxl"
                            alt="Responsive"
                            
                          />
                        </div>
                      ))}
                    </div>
                </div>
              ) : null}
            </section>
            {data?.result?.variations?.length ? (
              <section className="w-50 d-flex flex-column gap-3">
                {data?.result?.variations[0]?.attributeValues?.sort((a,b) => (a.attribute.weight - b.attribute.weight)).map(
                  (item) =>
                    item.attribute.isVisible &&
                    (!item.attribute.isValueTranslated ? (
                      <div key={item.id}>
                        <Label className="fw-semibold">
                          {" "}
                          {t(`${item?.attribute?.name}`)}
                        </Label>
                        <Input
                          className="form-control border-dashed p-3"
                          value={item.value}
                          readOnly
                        />
                      </div>
                    ) : (
                      item.attribute.isValueTranslated && (
                        <div>
                          <Label className="fw-semibold">
                            {t(`${item?.attribute?.name}`)}
                          </Label>
                          <div className="d-flex align-items-center gap-3">
                            {languages.map((lang) => {
                              const translation = item?.valueTranslations?.find(
                                (valueTranslation) =>
                                  valueTranslation.languageCode === lang
                              );

                              return (
                                translation && (
                                  <div key={lang} className="w-100">
                                    <InputLabel
                                      label={
                                        lang === "uz-Latn-UZ"
                                          ? t("uz")
                                          : lang === "uz-Cyrl-UZ"
                                          ? t("cryl")
                                          : t(lang)
                                      }
                                      inputProps={{
                                        className:
                                          "form-control border-dashed p-3 w-100",
                                        value: translation.text,
                                        readOnly: true,
                                      }}
                                    />
                                  </div>
                                )
                              );
                            })}
                          </div>
                        </div>
                      )
                    ))
                )}
              </section>
            ) : null}
          </div>
          </CardBody>
        </Card>
        </Col>
      </Row>
      )}
    </div>
  );
};

export default ProductsSingle;
