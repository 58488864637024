import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  Tooltip,
} from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import { useQuery } from "@tanstack/react-query";
import classnames from "classnames";
import ReserveTable from "../ui/reserve-table";
import { toast, ToastContainer } from "react-toastify";
import * as moment from "moment";
import { getOrders2Query } from "../../../queries";
import PageSize from "../ui/pageSize";
import FilterInputs from "../ui/filter-inputs";
import * as XLSX from "xlsx";
import { getOrders2 } from "../../../api";
import writeXlsxFile from "write-excel-file";
import { prettify } from "../ui/prettify";
import { config } from "../../../utils/config";
const values = {
  page: 1,
  size: 20,
  phoneNumber: null,
  subOrderState: null,
};

const Orders2 = () => {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState(values);
  const [excelLoading, setExcelLoading] = useState(false);
  const [status, setStatus] = useState(formValues.subOrderState);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const { data, isFetched } = useQuery({
    ...getOrders2Query(formValues),
    onError: (err) => {
      if (err.response && err.response.status === 500) {
        toast.error(
          t("An error occurred on the server. Please try again later.")
        );
      }
    },
  });

  const statusLabel = (state) => {
    switch (state) {
      case 0:
        return t("reserve_created");
      case 1:
        return t("reserve_undrway");
      case 2:
        return t("reserve_sent");
      case 3:
        return t("reserve_complated");
      case 4:
        return t("reserve_cancelled");
      case 5:
        return t("reserve_refund");
        case 6: 
        return t("reserve_processed")
        default:
          return t("reserve_delivered")
    }
  };

  const paymentType = (type) => {
    switch (type) {
      case 0:
        return t("Terminal");
      case 1:
        return t("Card");
      case 2:
        return t("Cash");
      case 3:
        return t("Wallet");
    }
  };

  const deliveryType = (type) => {
    switch (type) {
      case 0:
        return t("delivery");
      case 1:
        return t("pickup");
    }
  };

  const handleNavClick = (value) => {
    setFormValues((prevState) => ({ ...prevState, subOrderState: value }));
  };

  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };


  const currency = config.currency();
  const downloadExcel = async () => {
    setExcelLoading(true);

    const params = {
      size: 2147483647,
      page: 1,
      isAll: true,
      subOrderState: status,
    };

    try {
      const excelData = await getOrders2(params);

      const orders = excelData.result.items;

      const HEADER_ROW = [
        { value: t("orderId"), fontWeight: "bold" },
        { value: t("created_date"), fontWeight: "bold" },
        { value: t("Region"), fontWeight: "bold" },
        { value: t("District"), fontWeight: "bold" },
        { value: t("phone_number"), fontWeight: "bold" },
        { value: t("client_full_name"), fontWeight: "bold" },
        { value: t("SubOrdersID"), fontWeight: "bold" },
        { value: t("count"), fontWeight: "bold" },
        { value: t("payment_method"), fontWeight: "bold" },
        { value: t("status"), fontWeight: "bold" },
        { value: t("delivery_status"), fontWeight: "bold" },
        { value: t("Price"), fontWeight: "bold" },
        { value: t("Product name"), fontWeight: "bold" },
      ];

      const DATA_ROWS = orders.flatMap((item) => {
        return item.subOrders?.flatMap((child) => {
          return child.items.map((childItem) => {
            const totalPrice =
              childItem.variation.prices.find((price) => price.type === "Price")
                .value * childItem.count;

            return [
              { type: Number, value: item.id },
              {
                type: String,
                value: moment(item.createdDate).format("DD.MM.YYYY"),
              },
              { type: String, value: item.regionName },
              { type: String, value: item.districtName },
              { type: String, value: item.phoneNumber },
              { type: String, value: item.fullName },
              { type: Number, value: child.id },
              { type: Number, value: childItem.count },
              { value: paymentType(item.paymentType) },
              { value: statusLabel(child.state) },
              { value: deliveryType(item.deliveryType) },
              {
                value:
                  prettify(totalPrice) +
                  " " +
                  t(currency === "aed" ? "AED" : currency),
              },
              { type: String, value: childItem.variation.product.name },
            ];
          });
        });
      });

      const data = [HEADER_ROW, ...DATA_ROWS];

      await writeXlsxFile(data, { fileName: t("reserve") });
    } catch (error) {
      toast.error("Failed to export data.");
    } finally {
      setExcelLoading(false);
    }
  };

  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">{t("reserve")}</h5>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <Nav
                      className="nav-tabs nav-tabs-custom nav-success"
                      role="tablist"
                    >
                      {[null, 0, 1, 2, 3, 4, 5,6,7].map((state, index) => (
                        <NavItem key={state}>
                          <NavLink
                            className={classnames(
                              { active: formValues?.subOrderState === state },
                              "fw-semibold"
                            )}
                            onClick={() =>
                              handleNavClick(state) ?? setStatus(state)
                            }
                            href="#"
                          >
                            {t(
                              [
                                "all_reserve",
                                "reserve_created",
                                "reserve_undrway",
                                "reserve_sent",
                                "reserve_complated",
                                "reserve_cancelled",
                                "reserve_refund",
                                "reserve_processed",
                                "reserve_delivered"
                              ][index]
                            )}
                          </NavLink>
                        </NavItem>
                      ))}
                    </Nav>
                    <div className="d-flex align-items-center gap-2">
                      <FilterInputs
                        type={"filter"}
                        setFormValues={setFormValues}
                        fields={[
                          {
                            name: "phoneNumber",
                            type: "number",
                            placeholder: "Phone number",
                            button: true,
                          },
                        ]}
                      />
                      <div>
                        {excelLoading ? (
                          <Button color="success" disabled>
                            <Spinner size={"sm"} />
                          </Button>
                        ) : (
                          <div>
                            <span id="s1-wrapper">
                              <Button
                                color="success"
                                disabled={
                                  data?.result?.allCount === 0 ||
                                  formValues.subOrderState === null
                                }
                                onClick={downloadExcel}
                              >
                                {t("Export to Excel")}
                              </Button>
                            </span>
                            {(data?.result?.allCount === 0 ||
                              formValues.subOrderState === null) && (
                              <Tooltip
                                toggle={toggle}
                                isOpen={tooltipOpen}
                                target="s1-wrapper"
                              >
                                {data?.result?.allCount === 0
                                  ? t("No data")
                                  : t("select_status")}
                              </Tooltip>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {isFetched ? (
                    <div className="mt-2">
                      <ReserveTable
                        data={data?.result?.items}
                        pagination={{
                          currentPage: formValues?.page,
                          totalPages: data?.pagination?.TotalPages,
                          onChange: handlePageChange,
                        }}
                      />

                      <PageSize
                        formValues={formValues}
                        setFormValues={setFormValues}
                        tableKey={"reserve"}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Orders2;
