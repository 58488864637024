import React, { useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import * as moment from "moment";
//import images
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar8 from "../../assets/images/users/avatar-8.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar6 from "../../assets/images/users/avatar-6.jpg";
import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getNotificationQuery } from "../../queries";
import { putNotificationRead, putNotificationsRead, putNotificationsReadAll } from "../../api";

const NotificationDropdown = () => {
  //Dropdown Toggle
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const [notificationRead, setNotificationRead] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const clientsQueary = useQueryClient();
  const toggleNotificationDropdown = () => {
    setIsNotificationDropdown(!isNotificationDropdown);
  };
  const { data } = useQuery({
    ...getNotificationQuery({
      isRead: false,
    }),
  });
  const ReadNotifications = (id) => {
    setNotificationRead((prev) =>
      prev.includes(id) ? prev.filter((notId) => notId !== id) : [...prev, id]
    );
  };
  const { mutate } = useMutation({
    mutationFn: (id) => putNotificationRead(id),
  });
const { mutate: mutateReadAll } = useMutation({
    mutationFn: (data) => putNotificationsReadAll(data),
});
  const ReadNotification = (id) => {
    mutate(id, {
      onSuccess: () => {
        setNotificationRead([]);
        clientsQueary.invalidateQueries({ queryKey: ["notifications"] });
      },
    });
  };

  const ChooseReadNotification = async () => {
    await putNotificationsRead(notificationRead);
    clientsQueary.invalidateQueries({ queryKey: ["notifications"] });
  };
const AllReadNotification =  () => {
  mutateReadAll( {
    onSuccess: () => {
      clientsQueary.invalidateQueries({ queryKey: ["notifications"] });
    },
    onError: (error) => {
      console.log(error)
    }
  })
 
}

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-bell fs-22"></i>
          <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
            {data?.result?.item.length}
            <span className="visually-hidden">unread messages</span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head bg-primary bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    {" "}
                    Notifications{" "}
                  </h6>
                </Col>
                <Col>
                  <Button
                    hidden={!notificationRead.length}
                    onClick={ChooseReadNotification}
                  >
                    Read All
                  </Button>
                </Col>
              </Row>
            </div>

            <div className="px-2 pt-2">
              {/* <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        All (4)
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}
                                    >
                                        Messages
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggleTab('3'); }}
                                    >
                                        Alerts
                                    </NavLink>
                                </NavItem>
                            </Nav> */}
            </div>
            <Button onClick={AllReadNotification}>Read All</Button>
          </div>

          <TabContent activeTab={activeTab}>
            <TabPane
              tabId="1"
              className="py-2 ps-2"
              style={{
                maxHeight: "300px",
                minHeight: "300px",
                overflow: "auto",
              }}
            >
              {data?.result?.item.map((item) => (
                <SimpleBar key={item} className="pe-2">
                  <div className="text-reset notification-item d-block dropdown-item position-relative ">
                    <div className="d-flex">
                      <div className="avatar-xs me-3">
                        <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
                          <i className="bx bx-badge-check"></i>
                        </span>
                      </div>
                      <div className="flex-1">
                        <Link
                          to="#"
                          className="stretched-link"
                          onClick={() => ReadNotification(item.id)}
                        >
                          <strong className="mt-0 mb-2 lh-base">
                            {item.title}
                          </strong>
                        </Link>
                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                          <span className="d-flex align-items-center gap-1">
                            <i className="mdi mdi-clock-outline"></i>
                            {moment(item?.createdDate).format("DD.MM.YYYY")}
                          </span>
                        </p>
                      </div>
                      <div className="px-2 fs-15">
                        <div className="form-check notification-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={notificationRead.includes(item.id)}
                            onChange={() => ReadNotifications(item.id)}
                            value=""
                            id="all-notification-check01"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="all-notification-check01"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>
                </SimpleBar>
              ))}
            </TabPane>

            {/* <TabPane tabId="2" className="py-2 ps-2">
                <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">
                  <div className="text-reset notification-item d-block dropdown-item">
                    <div className="d-flex">
                      <img
                        src={avatar3}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-1">
                        <Link to="#" className="stretched-link">
                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                            James Lemire
                          </h6>
                        </Link>
                        <div className="fs-13 text-muted">
                          <p className="mb-1">
                            We talked about a project on linkedin.
                          </p>
                        </div>
                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                          <span>
                            <i className="mdi mdi-clock-outline"></i> 30 min ago
                          </span>
                        </p>
                      </div>
                      <div className="px-2 fs-15">
                        <div className="form-check notification-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="messages-notification-check01"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="messages-notification-check01"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-reset notification-item d-block dropdown-item">
                    <div className="d-flex">
                      <img
                        src={avatar2}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-1">
                        <Link to="#" className="stretched-link">
                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                            Angela Bernier
                          </h6>
                        </Link>
                        <div className="fs-13 text-muted">
                          <p className="mb-1">
                            Answered to your comment on the cash flow forecast's
                            graph 🔔.
                          </p>
                        </div>
                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                          <span>
                            <i className="mdi mdi-clock-outline"></i> 2 hrs ago
                          </span>
                        </p>
                      </div>
                      <div className="px-2 fs-15">
                        <div className="form-check notification-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="messages-notification-check02"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="messages-notification-check02"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-reset notification-item d-block dropdown-item">
                    <div className="d-flex">
                      <img
                        src={avatar6}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-1">
                        <Link to="#" className="stretched-link">
                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                            Kenneth Brown
                          </h6>
                        </Link>
                        <div className="fs-13 text-muted">
                          <p className="mb-1">
                            Mentionned you in his comment on 📃 invoice #12501.{" "}
                          </p>
                        </div>
                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                          <span>
                            <i className="mdi mdi-clock-outline"></i> 10 hrs ago
                          </span>
                        </p>
                      </div>
                      <div className="px-2 fs-15">
                        <div className="form-check notification-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="messages-notification-check03"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="messages-notification-check03"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="text-reset notification-item d-block dropdown-item">
                    <div className="d-flex">
                      <img
                        src={avatar8}
                        className="me-3 rounded-circle avatar-xs"
                        alt="user-pic"
                      />
                      <div className="flex-1">
                        <Link to="#" className="stretched-link">
                          <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                            Maureen Gibson
                          </h6>
                        </Link>
                        <div className="fs-13 text-muted">
                          <p className="mb-1">
                            We talked about a project on linkedin.
                          </p>
                        </div>
                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                          <span>
                            <i className="mdi mdi-clock-outline"></i> 3 days ago
                          </span>
                        </p>
                      </div>
                      <div className="px-2 fs-15">
                        <div className="form-check notification-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="messages-notification-check04"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="messages-notification-check04"
                          ></label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="my-3 text-center">
                    <button
                      type="button"
                      className="btn btn-soft-success waves-effect waves-light"
                    >
                      View All Messages{" "}
                      <i className="ri-arrow-right-line align-middle"></i>
                    </button>
                  </div>
                </SimpleBar>
              </TabPane>
              <TabPane tabId="3" className="p-4">
                <div className="w-25 w-sm-50 pt-3 mx-auto">
                  <img src={bell} className="img-fluid" alt="user-pic" />
                </div>
                <div className="text-center pb-5 mt-2">
                  <h6 className="fs-18 fw-semibold lh-base">
                    Hey! You have no any notifications{" "}
                  </h6>
                </div>
              </TabPane> */}
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
